import React from "react";

import "./explore.css"
export const TileList = ()=> {
    return    <div className="tileset_container">
        <div style={{maxWidth: 400}}>
        <span className="tile_text">
          This is how it works:
        </span>
        </div>


        <div className="container_2_tileset"
        >
                <span style={{maxWidth: 240,width: "100%"}}>
          <img src={process.env.PUBLIC_URL + "/assets/buy_text.png"} style={{maxWidth: 240,width: "100%"}}/>
        </span>
        <span style={{maxWidth: 240,width: "100%"}}>
          <img src={process.env.PUBLIC_URL + "/assets/mint_text.png"} style={{maxWidth: 240,width: "100%"}}/>
        </span>

            <span style={{maxWidth: 240,width: "100%",paddingTop: 12, boxSizing: "border-box"}}>
          <img src={process.env.PUBLIC_URL + "/assets/stake_text.png"} style={{maxWidth: 240,height:127}}/>
        </span>
            <span style={{maxWidth: 240,width: "100%"}}>
          <img src={process.env.PUBLIC_URL + "/assets/rewards_text.png"} style={{maxWidth: 240,width: "100%"}}/>
        </span>

        </div>

    </div>
}