import React, {useContext, useEffect, useState} from 'react';
import logo from './logo.svg';
import '../App.css';
import {Header} from "./header/headerComponent";
import {CollectBlock} from "./content/block_1/CollectBlock";
import {InuContainer} from "./InuContainer";
import {LabledImage} from "./LabledImage";
import {TileList} from "./TileList";
import {FAQElement} from "./FAQElement";
import {SocialBlock} from "./SocialBlock";
import {LinkBlock} from "./LinkBlock";
import {CoolestArtContainer} from "./CoolestArtContainer";
import {TheBestArtBlock} from "./TheBestArtBlock";
import {FooterComponent} from "./footer/FooterComponent";
import {LayoutWrapper} from "./LayoutWrapper";
import {DataComponent} from "./DataComponent";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';


export const HomeComponent = ()=> {
    const [topDistance,setTopDistance] = useState(window.scrollY);




    const [playGame, setIsPlayGame ] = useState(false);
    document.addEventListener("scroll", (evt)=> {
        setTopDistance(window.scrollY);
        console.info(window.scrollY)

    },{passive: true})
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        AOS.init()
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    return<div>



        <div className="overlay"></div>
        <div className="wave">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_2">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_3">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_4">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>

        <div style={{position: "relative", top: topDistance/2}}>
            <div className="coin_2">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/coin_angle2 1.png"}/>
      </span>
            </div>
        </div>

        <div style={{position: "relative", top: topDistance /7}}>
            <div className="coin_3">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/tilted1.png"} style={{maxWidth: 128, maxHeight: 128}}/>
      </span>
            </div>
        </div>
        <div style={{position: "relative", top: topDistance/3}}>
            <div className="coin_4">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/coin_angle2 1.png"}/>
      </span>
            </div>
        </div>

        <div className="blur_1"></div>
        <div className="blur_2"></div>
        <div className="blur_3"></div>
        <Header name={"test"}></Header>



        <LayoutWrapper>

            <div style={{width: "100%", justifyContent: "center",display: "flex"}}>
                {width>= 800 ?
                    <div className="art__container">
                        <CollectBlock></CollectBlock>
                        <InuContainer />

                    </div>
                    :
                    <div className="art__container">
                        <InuContainer />
                        <CollectBlock></CollectBlock>


                    </div>

                }

            </div>



            <div style={{position: "relative", top: 150}}   data-aos="fade-in" id="Transform">
                <TileList ></TileList>

            </div>

            <div className="block" style={{position: "relative", top: 290}} id="Mint">


                <div  data-aos="fade-in">
                    <CoolestArtContainer/>
                </div>
                <div  data-aos="fade-in">
                    <TheBestArtBlock></TheBestArtBlock>
                </div>



            </div>

            <div style={{display: "flex", justifyContent: "center",alignItems: "center"}} id="Collection">


                <div  id="Tokenomics" data-aos="fade-in" >
                    <div id="test">
                                        <span style={{fontSize: 40 + "!important"}} className="text_2" >
                Tokenomics
                    </span>
                        <span style={{fontSize: 40 + "!important"}} className="text_2" >100.000.000$OPI</span>
                        <span style={{fontSize: 40 + "!important"}} className="text_2" >0% Taxes</span>



                    </div>



                    {
                        width>=880 ?    <div style={{maxWidth: 800}}>
                            <img src={process.env.PUBLIC_URL + "/piechart.png"} style={{maxWidth:800}}></img>
                        </div> :
                            <div style={{maxWidth: 800}}>
                                <img src={process.env.PUBLIC_URL + "/piechart_1_new.png"} style={{maxWidth:800}}></img>
                            </div>

                    }

                </div>



            </div>
            <div style={{display: "flex",width: "100%",flexDirection:"column",alignItems:"center",gap:"2em",position: "relative", top: 430}}
            id="FAQs" data-aos="fade-in">
                <div>
                    <span className="FAQ_TEXT">
    FAQS
                    </span>
                </div>
                <FAQElement heading={"What is our goal?"} text={"Overall, our goal is to create a thriving NFT market on Arbitrum that benefits both artists and collectors alike. By providing a platform that is easy to use, affordable, and highly curated, we believe that we can help to broaden the reach and impact of the NFT market, while also supporting and empowering artists around the world. \n"}/>
                <FAQElement heading={"How often will new nfts be released?"} text={"Our goal is to release a new artpiece once a week\n"}/>
                <FAQElement heading={"How to buy OPI?"} text={"1. Create a Metamask wallet either using a computer or a mobile device. This will be where you will hold your $OPI. \n" +
                    "2. After the wallet is created, you need to transfer ETH from exchanges like Binance, Crypto.com, Coinbase, etc into your Metamask wallet to be able to interact with the Ethereum blockchain. \n" +
                    "3. Click the \"buy \" link at the top of the page. \n" +
                    "4. Connect your wallet with Uniswap. \n" +
                    "5. Press \"Select Token\" and enter the token address found in the \"FAQ\" section. \n" +
                    "6. Buy $OPI!\n"}/>
                <FAQElement heading={"Contract address"} text={"0x124114F8E5e6D08F6944ccc89e1704d6D984756e"}/>
            </div>


            <div style={{display: "flex", justifyContent: "center",position: "relative",top: 530,paddingBottom: 30, boxSizing: "border-box"}}>
                <FooterComponent/>
            </div>

        </LayoutWrapper>
    </div>;
}