
import "./SocialBlock.css"
export const SocialBlock = ()=> {


    return <div style={{display: "flex", flexDirection: "column", gap: "2em"}}>
        <div>
            <span>
                <img src={process.env.PUBLIC_URL + "/logo.png"} style={{maxWidth: 240}}/>
            </span>
        </div>

        <div style={{display: "flex",gap: "2em"}}>

            <a href="https://twitter.com/Olympianinu"  target={"_blank"}>
            <span>
                    <img src={process.env.PUBLIC_URL + "/assets/twitter.png"}/>
                </span>
            </a>



            <a href="https://t.co/j7DfXEpaPW"  target={"_blank"}>
                <span>
                    <img src={process.env.PUBLIC_URL + "/assets/telegram.png"}/>
                </span>
            </a>



            <a href="https://dexscreener.com/arbitrum/0xc7741eac18584d8fc58231a1e4cf117c08952b04" target={"_blank"}>

            <span>
                    <img src={process.env.PUBLIC_URL + "/assets/chart.png"}/>
                </span>
            </a>


        </div>
        <div className="text">
            <span className="text">

                <a href={"mailto:olympianinuV2@proton.me"} style={{textDecoration: "none"}}>Email us @ olympianinuV2@proton.me</a>
                <br/>
Copyright © 2022. All Rights Reserved.
            </span>
        </div>
    </div>
}