import {useEffect,FC} from "react";
import "./header.css"
import {Dropdown} from "./dropdown";
import {Searchbox} from "./Searchbox";
import {NavLink} from "react-router-dom";

type HeadrProps = {
    name:string;
}
export const Header:FC<HeadrProps> = ({name})=> {



    return<div className="header">
        <div style={{paddingTop: 4,boxSizing: "border-box"}}>
            <NavLink to={"/"} style={{textDecoration: "none"}}>
            <span>
                <img src={process.env.PUBLIC_URL + '/assets/logo.png'} style={
                    {maxWidth: 240,height: 32}

                } />
            </span>
            </NavLink>
        </div>

        <div>
            <NavLink to={"/"} style={{textDecoration: "none"}}>
            <span>Home</span>
        </NavLink>
        </div>
        <NavLink to={"/comingsoon"} style={{textDecoration: "none"}}>
        <Dropdown label={"Mint"} elements={["Art","Bla","Test"]}/>
        </NavLink>
        <NavLink to={"/comingsoon"} style={{textDecoration: "none"}}>
        <Dropdown label={"Gallery"} elements={["Art","Bla","Test"]}/>
        </NavLink>





    </div>;
}