import {Header} from "../header/headerComponent";
import {CollectBlock} from "../content/block_1/CollectBlock";
import {InuContainer} from "../InuContainer";
import {TileList} from "../TileList";
import {CoolestArtContainer} from "../CoolestArtContainer";
import {TheBestArtBlock} from "../TheBestArtBlock";
import {FAQElement} from "../FAQElement";
import {SocialBlock} from "../SocialBlock";
import {LinkBlock} from "../LinkBlock";
import React, {useEffect, useState} from "react";
import {MintBlock} from "../explore/MintBlock";
import {ExploreArtBlock} from "../explore/ExploreArtBlock";
import {ArtSlider} from "../explore/ArtSlider";

import "./resource.css"
import {FooterComponent} from "../footer/FooterComponent";
import {LayoutWrapper} from "../LayoutWrapper";
import {DataComponent} from "../DataComponent";
export const ResourceComponent = ()=> {
  const [topDistance,setTopDistance] = useState(window.scrollY);

  document.addEventListener("scroll", (evt)=> {
    setTopDistance(window.scrollY);
    console.info(window.scrollY)

  },{passive: true})
    return<div>
        <div className="overlay"></div>
        <div className="wave">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_2" >
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_3">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_4">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>






        <Header name={"test"}></Header>



        <LayoutWrapper>

            <div className="resource_container">



                <TheBestArtBlock></TheBestArtBlock><CoolestArtContainer></CoolestArtContainer>


            </div>



            <div style={{position: "relative", top: 350, width: "100%",display: "flex", justifyContent: "center", gap: "2em",flexDirection: "column"}}>


                <ArtSlider/>
                <ArtSlider/>

            </div>



















            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{display: "flex", justifyContent: "center",position: "relative",top: 550,paddingBottom: 30, boxSizing: "border-box"}}>
                    <FooterComponent/>
                </div>
            </div>
        </LayoutWrapper>
    </div>;
}