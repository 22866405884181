import {Header} from "../header/headerComponent";
import {LayoutWrapper} from "../LayoutWrapper";
import {CollectBlock} from "../content/block_1/CollectBlock";
import {InuContainer} from "../InuContainer";
import {TileList} from "../TileList";
import {CoolestArtContainer} from "../CoolestArtContainer";
import {TheBestArtBlock} from "../TheBestArtBlock";
import {FAQElement} from "../FAQElement";
import {FooterComponent} from "../footer/FooterComponent";
import React, {useContext, useEffect, useState} from "react";
import {EthereumContext, EthereumContextType, TransactionParameters} from "../../provider/EthereumProviderContext";

import {useNavigate} from "react-router-dom";

export const AdminComponent = ()=> {



    let [selectedAddress,setSelectedAdderss] = useState("");
    let [etherContext, setEtherContext]= useState({} as EthereumContextType);
    const [init,setInit] = useState(false)
    const [supply, setSupply] = useState(0);
    const [ipfsURI, setIPFSURI] = useState("");
    const [price, setPrice] = useState(0);
    const [endDate, setEndDte] = useState(new Date());

    const navigate = useNavigate();


    console.log("CTX==")
    let ctx:EthereumContextType = useContext(EthereumContext);


    let isOwner = ctx.owner_;
    console.log(isOwner)
    let tx_ = ctx?.ethereum?.selectedAddress;
    console.log(tx_)
    let isEqual = isOwner && tx_ && isOwner.toString().toLowerCase() == tx_.toString().toLowerCase();


    const startGenesis = async ()=> {
        if(!supply ||!ipfsURI || !price || !endDate || !ctx ||!ctx.ethereum || !ctx.opiNFTContract){

            console.log("Supply"  + supply)
            console.log("IPFS" + ipfsURI)
            console.log("price" + price)
            console.log("DATE" + endDate)
            console.log(ctx)
            alert("CHECK THE INPUTS")
            return;

        }
        let data = ctx.opiNFTContract.methods.startGenesisEdition (   price ,  supply, ipfsURI, endDate.getTime() / 1000).encodeABI()
        let txParams: TransactionParameters = {
            gas: "0x2710",
            from: ctx.owner_,
            maxPriorityFeePerGas: ctx.web3.utils.toHex(ctx.web3.utils.toWei("3","ether")),
            data: data,
            value: "0",
            chainId: "0x3",
            nonce: "0x00",
            maxFeePerGas: "0x09184e72a000",
            to: ctx.opiNFT
        }
        let txHash =await  ctx.ethereum.request(
            {
                method: "eth_sendTransaction",
                params: [txParams]
            }
        )
    }


    return <div>



        <div className="overlay"></div>
        <div className="wave">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_2">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_3">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_4">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>




        <div className="blur_1"></div>
        <div className="blur_2"></div>
        <div className="blur_3"></div>
        <Header name={"test"}></Header>



        <LayoutWrapper>


            {isEqual ?
                <div>
                <div style={{width: "100%", justifyContent: "center",display: "flex"}}>

                    <form style={{display: "flex", flexDirection: "column",gap: 16}}>
                        <div style={{display: "flex",gap: 8}}>
                        <span>
                            Supply
                        </span>
                            <span>
                            <input type={"number"} onInput={s=>{
                                setSupply(Number(s.currentTarget.value))}
                            }/>
                        </span>
                        </div>


                        <div style={{display: "flex",gap: 8}}>
                        <span>
                            End Time
                        </span>
                            <span>
                            <input type={"datetime-local"} onInput={s=>{
                                setEndDte(new Date(s.currentTarget.value))}
                            }/>
                        </span>
                        </div>

                        <div style={{display: "flex",gap: 8}}>
                        <span>
                            Price
                        </span>
                            <span>
                            <input type={"number"} onInput={s=>{
                                setPrice(Number(s.currentTarget.value ))}
                            }/>
                        </span>
                        </div>

                        <div style={{display: "flex",gap: 8}}>
                        <span>
                            IPFS
                        </span>
                            <span>
                            <input type={"text"} onInput={s=>{
                                setIPFSURI(s.currentTarget.value)
                            }
                            }/>
                        </span>
                        </div>


                        <button onClick={startGenesis}>Create Edition</button>


                    </form>

                </div>










                <div style={{display: "flex", justifyContent: "center",position: "relative",top: 530,paddingBottom: 30, boxSizing: "border-box"}}>
                    <FooterComponent/>
                </div>
                </div>
            :
            <div></div>}
        </LayoutWrapper>
    </div>;
}