export const CoolestArtContainer = () => {



    return <div style={{display: "flex", gap: "2em", alignItems: "center"}}>

        <div>
            <span>
                <img src={process.env.PUBLIC_URL + "/Art/deco_line.png"} style={{maxWidth: 23, width : "100%"}}/>
            </span>
        </div>
        <div>
            <span>
                <img src={process.env.PUBLIC_URL + "/Art/inu_artwork.png"} style={{maxWidth: 400, width : "100%"}}/>
            </span>
        </div>
        <div style={{position: "relative", bottom: 60}}>
            <span>
                <img src={process.env.PUBLIC_URL + "/Art/deco_block.png"} style={{maxWidth: 112, width: "100%"}}/>
            </span>
        </div>

    </div>;
}