import React, {useContext, useState} from "react";
import {EthereumContext, EthereumContextType, TransactionParameters} from "../provider/EthereumProviderContext";
import {useNavigate} from "react-router-dom";
import {Header} from "./header/headerComponent";
import {LayoutWrapper} from "./LayoutWrapper";
import {FooterComponent} from "./footer/FooterComponent";
import "./Soon.css"
export const ComingSoonComponent = () => {




    return  <div style={{maxHeight: 100 + "%",overflow: "hidden !important"}}>



        <div className="overlay"></div>







        <Header name={"test"}></Header>



        <LayoutWrapper>
            <div style={{display: "flex", justifyContent:"center"}}>
                <span className="soontexr" >
                Coming Soon ! Stay tuned
                </span>
            </div>
            <div style={{display: "flex", justifyContent: "center",marginTop: 150}}>
                <FooterComponent/>
            </div>
        </LayoutWrapper>


    </div>

}