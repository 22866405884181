
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed



import React, {useEffect, useState} from 'react';
import {AgChartOptions} from "ag-charts-community";
import {AgChartProps, AgChartsReact} from 'ag-charts-react/lib/agChartsReact';
function getData() {
    return [
        { label: 'LP Staking Rewards', value: 30, showInLegend:false     },
        { label: 'Team Allocation', value: 20 },
        { label: 'V1 Holder Airdrop', value: 40 },
        { label: 'Team Rewards', value: 10 }
    ];
}



    export const DataComponent = () => {


        const [topDistance, setTopDistance] = useState(window.scrollY);

        const [playGame, setIsPlayGame] = useState(false);
        document.addEventListener("scroll", (evt) => {
            setTopDistance(window.scrollY);
            console.info(window.scrollY)

        }, {passive: true})
        const [width, setWidth] = useState<number>(window.innerWidth);

        function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            if(isMobile){
                setOptions({
                    data: getData(),
                    background: {fill: "rgba(2,2,2,0.0001)"},
                    legend: {enabled: false},
                    width: 300,
                    height: 300,
                    series: [
                        {
                            type: 'pie',
                            angleKey: 'value',
                            calloutLabelKey: 'label',
                            sectorLabelKey: 'value',
                            sectorLabel: {
                                color: 'white',
                                fontWeight: 'bold',
                            },
                        },
                    ],
                });

            }
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        const isMobile = width <= 1200;
        const [options, setOptions] = useState({
            data: getData(),
            background: {fill: "rgba(2,2,2,0.0001)"},
            legend: {enabled: false},
            width: 400,
            height: 400,
            series: [
                {
                    type: 'pie',
                    angleKey: 'value',
                    calloutLabelKey: 'label',
                    sectorLabelKey: 'value',
                    sectorLabel: {
                        color: 'white',
                        fontWeight: 'bold',
                    },
                },
            ],
        });


        return isMobile ?
            <div style={{width: "100%", maxWidth: 350}}>

                {
                    // @ts-ignore
                    <AgChartsReact options={options}/>
                }:

            </div> :
            <div style={{width: "100%", maxWidth: 500}}>
                {
                    // @ts-ignore
                    <AgChartsReact options={options}/>
                }
            </div>
    }

