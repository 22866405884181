
import detectEthereumProvider from '@metamask/detect-provider'
import React, {useEffect, useState} from "react";
import {OpiNFT} from "../abis/OpiNFT";
import {OpiERC20} from "../abis/OpiERC20";
const Web3 = require("web3")
export type EthereumContextType = {
    web3: any;
    ethereum:any;

    opiInu: string;
    opiNFT:string;
    opiNFTContract: any;
    opiERC20Contract:any
    owner_: string;
    currentInfo: OlympianEdition
}



export type  TransactionParameters = {
    nonce: string, // ignored by MetaMask
    gas: string, // customizable by user during MetaMask confirmation.
    maxFeePerGas: string, // customizable by user during MetaMask confirmation.
    to:string, // Required except during contract publications.
    from: string, // must match user's active address.
    value: string, // Only required to send ether to the recipient from the initiating external account.
    data:
string, // Optional, but used for defining smart contract creation and interaction.
    chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    maxPriorityFeePerGas:string;
};

export type OlympianEdition = {

    id:number;
    price:number;
    maxSupply:number;
    ipfsURI:string;
    endingTime:number;
    currentMinted: number;

}
export const EthereumContext  = React.createContext({} as any);

export const EthereumProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [initialized, setInitialized] = useState(false);
    const [ethereumContextType, setEthereumContextType] = useState({});

    useEffect(() => {
        if (!initialized) {
            initContext().then((s) => {
                if (s) {
                    setEthereumContextType(s);
                    setInitialized(true);
                }
            });
        }
    }, [initialized]);

    return <EthereumContext.Provider value={ethereumContextType}>{children}</EthereumContext.Provider>;
};
export const initContext = async  ()=>{
    if(1+1+1 ==3)return;
    let providedContent = await detectEthereumProvider()
    if(!providedContent) return React.createContext("");
    // @ts-ignore
    if(providedContent !== window['ethereum'])return React.createContext("");

    const web3 = new Web3(providedContent);


    // @ts-ignore
    const ether = window['ethereum']
    console.log(web3)
    let accs =        await  ether.request({ method: 'eth_requestAccounts' })
    if(!accs){
        alert("NO METAMASK FOUND ")
    }// @ts-ignore


    await ether.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(5) }]
    });

    let opiNFT = "0x0F36102308DCDC3575dc459d05B8398745b761C5"
    let opiErc20 = "0x064feDa6d48b34F9f153dD4eFC75f1D7a2132222"
    let opiInuContract = new web3.eth.Contract(OpiERC20,opiErc20);
    let opiNFTContract = new web3.eth.Contract(OpiNFT, opiNFT)
    let contractOwner = await opiNFTContract.methods.owner().call()

    let currentEdition = await  opiNFTContract.methods.currentEditionId().call()
    let mintInfo_ = await opiNFTContract.methods.getTokenEdition(currentEdition).call();
    let totalSuspply = await opiNFTContract.methods.getTotalSupply(currentEdition).call();


        let info = {
            currentMinted: totalSuspply,
            price: mintInfo_.price,
            maxSupply: mintInfo_.maxSupply,
            id: mintInfo_.id,
            ipfsURI: mintInfo_.ipfsURI,
            endingTime: mintInfo_.endingTime
        }



        return {
            ethereum: ether, web3: web3,
            opiInu: opiErc20,
            opiNFT: opiNFT,
            opiERC20Contract: opiInuContract,
            opiNFTContract: opiNFTContract,
            owner_: contractOwner,
            currentInfo: info


        } as EthereumContextType



}

