import "../InuContainer.css"
export const ExploreArtBlock = ()=> {




    return <div style={{display: "flex", justifyContent: "center",marginBottom: 20,boxSizing: "border-box"}}>

    <span>
        <img src={process.env.PUBLIC_URL + "/explore/art_work.png"} className="responsive"/>
    </span>
    </div>
}