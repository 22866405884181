import "./InuContainer.css"
import {useEffect, useState} from "react";
export const InuContainer = ()=> {



    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.scrollTo(0,0)
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return isMobile ? <div style={{display: "flex", justifyContent: "center",flexDirection: "column",alignItems: "center"}}>
        <div style={{marginBottom: 24 , boxSizing: "border-box"}}>
            <span className="text_1_CONTAINER_ART">With olympian inu you can </span>
        </div>

        <span>
            <img src={process.env.PUBLIC_URL + "/assets/container_complete.png"} style={{width: "100%",maxWidth: 500}}/>
        </span>
    </div>
        : <div style={{display: "flex", justifyContent: "center"}}>

        <span>
            <img src={process.env.PUBLIC_URL + "/assets/container_complete.png"} style={{width: "100%",maxWidth: 500}}/>
        </span>
        </div>
}