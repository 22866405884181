import "./LinkBlock.css"
type LinkBlockProps = {
    label: string;
    elements: string[];

}

export const LinkBlock: React.FC<LinkBlockProps> = (props) => {

    return <div className="link_block_container">
        <div>
            <span className="link_block">{props.label}</span>
        </div>


        {props.elements.map(s=> {
            return <div>
                <a href={"#"+s.toString()} style={{textDecoration: "none"}}>
                    <span className="link_block_element">
                    {s}
                </span>
                </a>
            </div>
        })}

    </div>

}