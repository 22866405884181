import "./TheBestArt.css"
import "./content/block_1/collect.css"
import {NavLink} from "react-router-dom";
export const TheBestArtBlock = ()=> {



    return <div className="art_container">
        <div>
            <span className="best_art_heading">
                The Coolest <span className="ORANGE best_art_heading">NFT</span>s on Arbitrum
            </span>
        </div>

        <div>
            <span className="exploration_text">
            The coolest Art on arbitrum brought to to you  by us, carefully selected, by the Olympian Inu Team
            </span>
        </div>

        <div>
            <span>
                <NavLink to={"/comingsoon"}style={{textDecoration: "none",cursor: "pointer"}}>
                        <button className="explore_btn">
                    Mint
                </button>
                </NavLink>


            </span>
        </div>

    </div>
}