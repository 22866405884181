
import "./ArtSlider.css"
import {useState} from "react";

const assets = ["/explore/SkyDiverFox_Demeter_1st.jpg","/explore/SkyDiverFox_Demeter_1st.jpg","/explore/SkyDiverFox_Demeter_1st.jpg","/explore/SkyDiverFox_Demeter_1st.jpg","/explore/SkyDiverFox_Demeter_1st.jpg"]
export const ArtPopUp =(props:any)=> {
    const asset  = props.asset;

    return <div style={{width: "100%", height: "100%",display: "flex", alignItems: "center", justifyContent: "center",position: "absolute",
    backgroundColor:"rgba(255,255,255,0.5)",zIndex:2}}>
        <div className="popup">
            <div style={{display: "flex", justifyContent:"end"}}>
            <span onClick={props.callback}>
                <img src={process.env.PUBLIC_URL + "/close.png"}/>
            </span>
            </div>


            <div style={{display:"flex",gap:"2em"}}>


                <div className="asset">
                <span>
                    <img src={asset} className="asset" style={{border: "4px solid"}}/>
                </span>
                </div>
                <div>
                    <div className="text_1">NFT / Artist Name</div>

                    <div className="text_2">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</span>
                    </div>

                    <div style={{display: "flex", gap: "1em"}}>
                        <div>
                <span>
                    <img src={process.env.PUBLIC_URL + "/ig.png"}/>
                </span>
                        </div>
                        <div>
                <span>
                    <img src={process.env.PUBLIC_URL + "/tw.png"}/>
                </span>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>

}
export const ArtSlider = ()=> {
    const [popUp,setPopUp] = useState(false);
    const [selectedAsset, setAsset] = useState("")


    const closeModal = ()=> {
        setPopUp(false);
    }

    return<div>
        {popUp ? <div><ArtPopUp callback={()=> {
            closeModal()}
        } asset={selectedAsset}></ArtPopUp></div>:""}

        <div className="slider">

            {assets.map(s=> {
                return <div onClick={()=> {

                    setPopUp(true)
                    setAsset(s)
                }

                } >
            <span>
                <img src={process.env.PUBLIC_URL + s} style={{maxWidth: 203,maxHeight: 203
                    ,width:203,borderRadius: 11, border: "4px solid #E77D1F"}}/>
            </span>
                </div>

            })}

        </div>


    </div>
}