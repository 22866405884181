import {Header} from "./header/headerComponent";
import {CollectBlock} from "./content/block_1/CollectBlock";
import {InuContainer} from "./InuContainer";
import {TileList} from "./TileList";
import {CoolestArtContainer} from "./CoolestArtContainer";
import {TheBestArtBlock} from "./TheBestArtBlock";
import {FAQElement} from "./FAQElement";
import {SocialBlock} from "./SocialBlock";
import {LinkBlock} from "./LinkBlock";
import React, {useContext, useEffect, useState} from "react";
import {MintBlock} from "./explore/MintBlock";
import {ExploreArtBlock} from "./explore/ExploreArtBlock";
import {ArtPopUp, ArtSlider} from "./explore/ArtSlider";

import "./explore.css"
import {FooterComponent} from "./footer/FooterComponent";
import {LayoutWrapper} from "./LayoutWrapper";

import {
  EthereumContext,
  EthereumContextType,
  OlympianEdition,
  TransactionParameters
} from "../provider/EthereumProviderContext";


export const ExploreComponent = ()=> {

  const [width, setWidth] = useState<number>(window.innerWidth);

  const [initialized, setInitialized] = useState(false)
  const [ethereumContext,setEthereumContext] = useState<EthereumContextType >({} as EthereumContextType)
  const [inuEdition, setInuEdition] = useState<OlympianEdition>({} as OlympianEdition)


  let context_:EthereumContextType = useContext(EthereumContext);




  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }





  useEffect(() => {
    window.scrollTo(0,0)
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

/*  const Init= async ()=>{
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if(EthereumContext_ && !initialized){
      // eslint-disable-next-line react-hooks/rules-of-hooks
      let    ContextValues:EthereumContextType = useContext(EthereumContext_);
      if(ContextValues){
        setEthereumContext(ContextValues)
        setInitialized(true)
      }
    }


  }*/
  const mintEdition = async ()=>{
    if(!context_ || !context_.ethereum)return;
    let abi = context_.opiNFTContract.methods.mint().encodeABI();
    let txParams : TransactionParameters = {
      data: abi,
      from: context_.ethereum.selectedAddress,
      to: context_.opiNFT,
      gas: context_.web3.utils.toHex("150000"),
      chainId: "0x3",
      maxFeePerGas: context_.web3.utils.toHex(context_.web3.utils.toWei("50","gwei")),
      maxPriorityFeePerGas: context_.web3.utils.toHex(context_.web3.utils.toWei("3","gwei")),
      nonce: "0x00",
      value: "0"
    }
     let txParamsAllowance = {...txParams};
    let abi_allowance = context_.opiERC20Contract.methods.increaseAllowance( context_.opiNFT,context_.currentInfo.price * 2 ).encodeABI();
    txParamsAllowance.data = abi_allowance;
    txParamsAllowance.to = context_.opiInu;
    txParamsAllowance.gas =   context_.web3.utils.toHex("150000")

    let approved = await context_.opiERC20Contract.methods.allowance(context_.ethereum.selectedAddress,context_.opiNFT).call();

    console.log("APPROVED TOKENS " + approved)
    let userBalance = await     context_.opiERC20Contract.methods.balanceOf(context_.ethereum.selectedAddress).call();
    console.log(userBalance)
    console.log(context_.currentInfo.price)
    if(Number(userBalance)< Number(context_.currentInfo.price)){
      alert("Not enough opi tokens...")
      return;
    }

    if(approved< context_.currentInfo.price){
      const hash = await context_.ethereum.request(
          {
            method: "eth_sendTransaction",
            params: [txParamsAllowance]
          }
      )

      alert("CHANGED ALLOWANCE PLEASE RETRY AFTER TX")
      return
    }

    const txHash = await context_.ethereum.request(
        {
          method: "eth_sendTransaction",
          params: [txParams]
        }
    )


  }



  /*Init().then()*/
  const isMobile = width <= 768;
  const [topDistance,setTopDistance] = useState(window.scrollY);
  document.addEventListener("scroll", (evt)=> {
    setTopDistance(window.scrollY);
    console.info(window.scrollY)

  },{passive: true})
    return<div>
        <div className="overlay"></div>
        <div className="wave">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_2">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_3">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>
        <div className="wave_4">
      <span>
        <img src={process.env.PUBLIC_URL + "/assets/wave.svg"}/>
      </span>
        </div>





        <Header name={"test"}></Header>


      <LayoutWrapper>



        <div style={{display: "flex", justifyContent: "center"}}>
          <div className="explore_container" style={{width: "100%"}}>


            {  (width>1023 ?

                <div className="explore_container">
                  <div >


                    {context_.currentInfo ?
                        <MintBlock click={()=>{
                          mintEdition().then()}
                        }
                                   price={context_.currentInfo.price} maxSupply={context_.currentInfo.maxSupply} currentMinted={context_.currentInfo.currentMinted} endDate={context_.currentInfo.endingTime}/>:<div></div>}
                  </div>
                  <ExploreArtBlock />
                </div>


                :
                <div className="explore_container">
                  <div className="order_0">
            <span>
                <img src={process.env.PUBLIC_URL + "/explore/gen_mint.png"}/>
            </span>
                  </div>
                  <ExploreArtBlock />
                  <div ><MintBlock click={()=>{
                  mintEdition().then()}
                  }
                                   price={context_.currentInfo.price} maxSupply={context_.currentInfo.maxSupply} currentMinted={context_.currentInfo.currentMinted} endDate={context_.currentInfo.endingTime}/></div>

                </div>)}

            <div>

            </div>




          </div>
        </div>
        <div style={{width: "100%",position: "relative", top:200}}>
          <ArtSlider/>

        </div>













        <div style={{display: "flex", justifyContent: "center",position: "relative",top: 350,paddingBottom: 30, boxSizing: "border-box"}}>

          <FooterComponent/>
        </div>
      </LayoutWrapper>

    </div>;
}