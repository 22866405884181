
import "./MintBlock.css";
import "../explore.css";
import {useEffect, useState} from "react";

type MintBlock = {
    currentMinted: number,
    maxSupply: number,
    endDate: number,
    click: Function;
    price: number
}
export const MintBlock = (props:MintBlock) => {

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 1023;
    return isMobile  ?(
        <div className="mint_block_container">
            <div >
            <span className="minted_text">
                {props.currentMinted} / {props.maxSupply} MINTED
            </span>
            </div>

            <div style={{display:"flex"
            }}>
            <span>
                <button className="mint_btn"  onClick={()=>{
                    props.click()
                    console.log("CLICKED")}
                }>Mint Now</button>
            </span>
            </div>

            <div >
            <span className="text_1">
                Have your ETH ready!
            </span>
            </div>

            <div className="order_1">
            <span className="last_text">
                 . PRICE {props.price}OPI$ + GAS
            </span>
            </div>
            <div >
            <span className="last_text">
                MINT IS LIVE UNTIL <span className="orange_text"> {new Date(props.endDate *1000) .toDateString()}H</span>
            </span>
            </div>

            <div>
            <span className="last_text">
                PRESALE : {props.currentMinted === props.maxSupply || new Date().getTime() >= props.endDate ?  "LIVE": "SOLDOUT"}
            </span>
            </div>


        </div>
    ) : <div className="mint_block_container">
        <div className="order_0">
            <span>
                <img src={process.env.PUBLIC_URL + "/explore/gen_mint.png"}/>
            </span>
        </div>
        <div >
            <span className="text_1">
                Have your ETH ready!
            </span>
        </div>






        <div className="order_1">
            <span className="last_text">
                MAX 2 NFTS PER WALLET . PRICE {props.price} OPI$ + GAS
            </span>
        </div>
        <div >
            <span className="last_text">
                MINT IS LIVE UNTIL <span className="orange_text"> {new Date(props.endDate *1000) .toDateString()}</span>
            </span>
        </div>
        <div >
            <span className="minted_text">
                {props.currentMinted} / {props.maxSupply} MINTED
            </span>
        </div>
        <div>
            <span className="last_text">
                PRESALE : {props.currentMinted === props.maxSupply || new Date().getTime() >= props.endDate ?  "LIVE": "SOLDOUT"}
            </span>
        </div>
        <div style={{display:"flex"
        }}>
            <span>
                <button className="mint_btn" onClick={()=>{
                props.click()
                console.log("CLICKED")}
                }>Mint Now</button>
            </span>
        </div>

    </div>
}