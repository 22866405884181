import React, {Context, useContext, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import {Header} from "./components/header/headerComponent";
import {CollectBlock} from "./components/content/block_1/CollectBlock";
import {InuContainer} from "./components/InuContainer";
import {LabledImage} from "./components/LabledImage";
import {TileList} from "./components/TileList";
import {FAQElement} from "./components/FAQElement";
import {SocialBlock} from "./components/SocialBlock";
import {LinkBlock} from "./components/LinkBlock";
import {CoolestArtContainer} from "./components/CoolestArtContainer";
import {TheBestArtBlock} from "./components/TheBestArtBlock";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomeComponent} from "./components/HomeComponent";
import {ExploreComponent} from "./components/ExploreComponent";
import {ResourceComponent} from "./components/resource/ResourceComponent";
import {TestComponent} from "./components/TestComponent";
import {EthereumProvider} from "./provider/EthereumProviderContext";
import {AdminComponent} from "./components/admin/AdminComponent";
import {ComingSoonComponent} from "./components/ComingSoonComponent";



 function App() {








     return     <BrowserRouter>

      <EthereumProvider >
          <Routes>




              <Route path="/" element={<HomeComponent />} />
              <Route path="/explore" element={<ExploreComponent/>}/>
              <Route path="/resource" element={<ResourceComponent/>}/>
              <Route path="/test" element={<TestComponent test={""}/>}/>
              <Route path="/admin" element={ <AdminComponent/> }/>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Route path="/comingsoon" element={ <ComingSoonComponent /> }/>




          </Routes>
      </EthereumProvider>
  </BrowserRouter>
}

export default App;
