import {SocialBlock} from "../SocialBlock";
import {LinkBlock} from "../LinkBlock";
import React from "react";

export const FooterComponent = ()=> {
    return            <div style={{display: "flex",gap:"2em",justifyContent: "space-around",maxWidth: 1320,width: "100%",flexWrap:"wrap"}}>
        <SocialBlock/>
        <LinkBlock label={"Home"} elements={["Tokenomics","FAQs","Gallery"]}></LinkBlock>
        <LinkBlock label={"NFTs"} elements={["Mint","Transform","Collection"]}></LinkBlock>


    </div>
}