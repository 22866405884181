
import "./FAQElement.css"
import {useEffect, useMemo, useState} from "react";
import AOS from "aos";
type FAQElementType = {
    heading: string;
    text: string
}
export const FAQElement: React.FC<FAQElementType> = (props)=> {

    let display = false;
    const [elements, setElements] = useState([""])
    const [isCollapsed,setIsCollapsed] = useState(false);

    const [displayText, setDisplayText] = useState("");
    const collapseContainer = ()=> {
        setIsCollapsed(!isCollapsed);
    }

    useEffect(()=>{
        let elements_ = props.text.split("\n");
        setElements(elements_)
    },[props.text,elements])
    let string = ""

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        AOS.init()
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return isCollapsed ?

        <div  className="" style={{width: "70%",display: "flex", flexDirection: "column",   border: "0.5px solid #621311",borderRadius:12,padding: "20px 25px",gap:"2em"}} onClick={collapseContainer}>
            <div style={{display: "flex", gap: "2em"}}>
                <span className="heading_FAQ">
                    {props.heading}
                </span>
            </div>
            <div style={{display: "flex", gap: "2em"}}>
                <span className="container_text_FAQ" style={{display: "flex", flexDirection: "column", gap: 20}}>


                    {  elements.length>0 ?

                        (elements.map(s=>{
                            return  <div>{s.toString().startsWith("0x") && width>=880 ? <a href={"https://arbiscan.io/address/" + s} style={{ textDecoration: "none",cursor: "pointer",display: "flex", gap: 8}}><span><img src={process.env.PUBLIC_URL + "/arbitrum.svg" } style={{width: 24, height: 24}}/> </span>{s}</a>

                                :
                                s.startsWith("0x")? <div><a href={"https://arbiscan.io/address/" + s} style={{ textDecoration: "none",cursor: "pointer",display: "flex", gap: 8}}><span><img src={process.env.PUBLIC_URL + "/arbitrum.svg" } style={{width: 24, height: 24}}/> </span>{s.slice(0,10)}</a></div>: <div> {s}</div>
                            }</div>
                        }))
                        :<div>{props.text}</div>}



                </span>

            </div>


        </div>

        :
        <div style={{display: "flex", flexDirection: "column",   border: "0.5px solid #621311",width: "70%",padding: "20px 25px",borderRadius: 12}} onClick={collapseContainer}>
            <div style={{display: "flex", gap: "2em"}}>
                <span className="heading_FAQ">
                    {props.heading}
                </span>

            </div>
        </div>

}