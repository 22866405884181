import React from "react";
import {DataComponent} from "./DataComponent";


type  TestComponentProps ={
    test:string
}
export const TestComponent : React.FC<TestComponentProps> = (props)=> {
    return <div>

        <DataComponent/>
    </div>
}

