import "./header.css";
import React, {useState} from "react";
type DropdownProps= {
    label: string;
    elements: string[],
}
export const Dropdown: React.FC<DropdownProps> = (props:DropdownProps)=> {

    const [isActive, setIsActive ] = useState(false);


    const changeState = ()=> {
        setIsActive(!isActive);
        console.info("New State " + isActive)
    }

    return isActive ?
        <div className="dropdown dropdown_active">

        <div className="dropdown">
            <span>{props.label} </span>

        </div>


            <div className={"dropdown_active"} style={{position: "absolute",top: 60}}>
                {props.elements.map(current => {
                    return <div>
                        <span>{current}</span>
                    </div>
                })}
            </div>

        </div> :
        <div className="dropdown">

            <span>{props.label} </span>


        </div>
}