import "./collect.css"
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
export const  CollectBlock = ()=> {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.scrollTo(0,0)
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;







    return isMobile ? <div className="collect_block">
        <div>
        </div>
        <div>
            <span className="text_2">
             Collect, Trade<br/>
                and stake <br/><div style={{marginLeft: 18, boxSizing: "border-box",fontSize: 40}}>your<span className="text_2 ORANGE"> <strong style={{fontSize: 40}}> NFTs</strong> </span></div>
            </span>
        </div>
        <div>
            <span className="text_3">
             Designed by curated promintent web3 artists
            </span>
        </div>
        <div className="buttons">
            <span>
                <a href={"https://app.sushi.com/swap?outputCurrency=0x124114f8e5e6d08f6944ccc89e1704d6d984756e&chainId=1"} style={{cursor: "pointer", textDecoration: "none"}}
                target={"_blank"}>
                <button className="sell_btn">
                    Buy

                </button>
                </a>

            </span>


            <Link to={"/comingsoon"} style={{textDecoration: "none"}}>
                <div>
                <span>
                    Mint
                </span>



                </div>
            </Link>

        </div>

    </div> :
        <div className="collect_block">

            <div>
            <span className="text_1">
               With Olympian Inu you can
            </span>
            </div>
            <div>
            <span className="text_2">
             Collect, Trade<br/><br/>
                and stake <br/><br/><div style={{marginLeft: 90, boxSizing: "border-box"}}>your<span className="text_2 ORANGE"> <strong style={{fontSize: 68}}>NFTs</strong> </span></div>
            </span>
            </div>
            <div>
            <span className="text_3">
             Designed by curated promintent web3 artists
            </span>
            </div>
            <div className="buttons">
            <span>
                <a href={"https://app.sushi.com/swap?outputCurrency=0x124114f8e5e6d08f6944ccc89e1704d6d984756e&chainId=1"} style={{cursor: "pointer", textDecoration: "none"}}
                   target={"_blank"}>
                <button className="sell_btn">
                    Buy

                </button>
                </a>

            </span>


                <Link to={"/comingsoon"} style={{textDecoration: "none"}}>
                    <div>
                <span>
                    Mint
                </span>



                    </div>
                </Link>

            </div>

        </div>
}